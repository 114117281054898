import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as mockxhr from 'mockxhr'
import { actions, API } from "./flux"
import * as qs from 'query-string'
import * as _ from 'lodash'
import * as moment from 'moment'
import * as util from "./util"
import Header from "./components/Header4"
import Footer from "./components/Footer2"
import Partner from "./components/Partners2"
import { Carousel, Icon } from 'antd'

import "./styles/default.less"
import './styles/desktop/Index2019.less'

interface IState {
  slides: any[]
  headlines: any[]
  newsRank: any[]
  rankScroll: boolean
  theses: any[]
  thesesRank: any[]
  adData: any
  careers: any[]
  jobs: any[]
  employerNames: any[]
  science60s: any[]
  daily: any[]
  careerPage: number
  periodicalList: IPeriodical[]
}

interface IPeriodical {
  checkname: string
  url: string
  logo?: string
}

const RANK_COLOR = {
  0: '#3ec4f9',
  1: '#3ec4f9',
  2: '#3ec4f9',
}

let timer_job
let timer_news
let timer_theses
let speed = 50

export default class View extends React.Component<{}, IState> {
  adStore: any = {
      // PC_HOME_SIDE_THESIS: {},
      // PC_HOME_SIDE_JOB1: {},
      // PC_HOME_SIDE_JOB2: {},
      HOME_SHORT_1: {},
      HOME_SHORT_2: {},
      HOME_SHORT_3: {},
      HOME_SHORT_4: {},
      // HOME_SHORT_5: {},
      HOME_LONG_1: {},
      HOME_LONG_2: {},
      HOME_LONG_3: {},
      HOME_LONG_4: {},
      // HOME_LEFT_1: {},
      // HOME_LEFT_2: {},
      // HOME_LEFT_3: {},
  }

  constructor (props) {
    super(props)
    this.state = {
      slides: [],   // 跑马灯
      headlines: [],  // 新闻
      newsRank: [],   // 新闻排行
      rankScroll: false,
      theses: [{subject: ''}],   // 论文
      thesesRank: [],   // 论文排行
      adData: {},   // 广告
      careers: [],  // 科研生涯
      jobs: [],   // 招聘
      employerNames: [],   // 热门机构排行
      science60s: [],   // 科学60秒
      daily: [],   // 科研圈日报
      careerPage: 0,   // 科研镇压页码
      periodicalList: [
        {
          checkname: 'RNA Biology',
          url: 'https://www.tandfonline.com/journals/krnb20?utm_source=banner&utm_medium=banner_ad&utm_campaign=JRH30616',
          logo: 'https://cdn.linkresearcher.com/home/article/journal_display/11_%E6%B3%B0%E5%8B%92%E5%BC%97%E6%9C%97%E8%A5%BF%E6%96%AF%E5%87%BA%E7%89%88%E9%9B%86%E5%9B%A2_RNA%20Biology.png-transform2webp',
        },
        {
          checkname: 'Frontiers of Computer Science',
          url: 'https://journal.hep.com.cn/fcs/EN/current',
          logo: 'https://cdn.linkresearcher.com/home/article/journal_display/15_%E9%AB%98%E7%AD%89%E6%95%99%E8%82%B2%E5%87%BA%E7%89%88%E7%A4%BE_Frontiers%20of%20Computer%20Science.png-transform2webp',
        },
        {
          checkname: 'Bioactive Materials',
          url: 'https://www.keaipublishing.com/en/journals/bioactive-materials/',
          logo: 'https://cdn.linkresearcher.com/home/article/journal_display/13_%E7%A7%91%E7%88%B1_Bioactive%20Materials.png-transform2webp',
        },
        {
          checkname: 'Frontiers in Immunology',
          url: 'https://www.frontiersin.org/journals/immunology/about',
          logo: 'https://cdn.linkresearcher.com/home/article/journal_display/9_Frontiers_Frontiers%20in%20Immunology%20.png-transform2webp',
        },
        {
          checkname: 'IEEE Open',
          url: 'https://open.ieee.org/',
          logo: 'https://cdn.linkresearcher.com/index_ieee%20open.jpg-transform2webp',
        },
        {
          checkname: 'Fundamental Research',
          url: 'https://www.sciencedirect.com/journal/fundamental-research',
          logo: 'https://cdn.linkresearcher.com/home/article/journal_display/14_%E7%A7%91%E7%88%B1_Fundamental%20Research.png-transform2webp',
        },
        {
          checkname: 'Cell',
          url: 'https://www.cell.com/cell/current',
          logo: 'https://cdn.linkresearcher.com/home/article/journal_display/5_%E7%BB%86%E8%83%9E%E5%87%BA%E7%89%88%E7%A4%BE_Cell.png-transform2webp',
        },
        {
          checkname: 'Scientific Reports',
          url: 'https://www.nature.com/srep/',
          logo: 'https://cdn.linkresearcher.com/index_scientific%20%20repotts.jpg-transform2webp',
        },
        {
          checkname: 'Nature Communications',
          url: 'https://www.nature.com/ncomms/',
          logo: 'https://cdn.linkresearcher.com/index_nature%20communications.jpg-transform2webp',
        },
        {
          checkname: 'JPhys Energy',
          url: 'https://iopscience.iop.org/journal/2515-7655',
          logo: 'https://cdn.linkresearcher.com/home/article/journal_display/18_%E8%8B%B1%E5%9B%BD%E7%89%A9%E7%90%86%E5%AD%A6%E4%BC%9A%E5%87%BA%E7%89%88%E7%A4%BE_JPhys%20Energy.png-transform2webp',
        },
        {
          checkname: 'Critical Care',
          url: 'https://ccforum.biomedcentral.com/',
          logo: 'https://cdn.linkresearcher.com/home/article/journal_display/3-2_BMC_Critical%20Care_2.png-transform2webp',
        },
        {
          checkname: 'Frontiers of Medicine',
          url: 'https://journal.hep.com.cn/fmd/EN/2095-0217/home.shtml',
          logo: 'https://cdn.linkresearcher.com/home/article/journal_display/16_%E9%AB%98%E7%AD%89%E6%95%99%E8%82%B2%E5%87%BA%E7%89%88%E7%A4%BE_Frontiers%20of%20Medicine.png-transform2webp',
        },
        {
          checkname: 'Nanophotonics',
          url: 'https://www.degruyter.com/journal/key/nanoph/html?utm_source=display&utm_medium=banner&utm_campaign=nanophotonics_linkresearcher_cn&utm_term=lw&utm_content=submissions',
          logo: 'https://cdn.linkresearcher.com/home/article/journal_display/7_%E5%BE%B7%E5%8F%A4%E6%84%8F%E7%89%B9_Nanophotonics.png-transform2webp',
        },
        {
          checkname: 'RSC',
          url: 'https://www.rsc.org/journals-books-databases/about-journals/',
          logo: 'https://cdn.linkresearcher.com/index_rsc.jpg-transform2webp',
        },
      ],   // 期刊数据
    }
    this.getMoreCareersData = this.getMoreCareersData.bind(this)
    this.Lazyload = this.Lazyload.bind(this)
    this.throttle = this.throttle.bind(this)
  }

  componentDidMount () {
    // 跑马灯数据
    mockxhr.POST(API.promotion.search("INDEX_SLIDE", "_", 99, 0), {}, resp => {
        this.setState({
            slides: resp.hits.sort((a, b) => b.updatedAt - a.updatedAt).sort((x, y) => y.weight - x.weight),
            // slideMarginLeft: -1200 * resp.hits.length,
        })
    })

    // 新闻数据
    actions.api.article.search({
      type: "NEWS", from: 0, size: 9,
      request: {
          filters: { status: true },
          sort: [{ weight3: "DESC" }, { "onlineTime": "DESC" }],
      }
    }, res => {
      this.setState({ headlines: res.hits })
    })

    // 新闻排行
    mockxhr.POST(`/api/articles/search/week?type=NEWS&from=0&size=15`, {
      filters: {},
      sort: [],
    }, resp => {
      this.setState({ newsRank: resp.hits }, () => this.setState({rankScroll: (this.refs.rank as HTMLElement).scrollHeight > 595}))
    })

    // 科研圈日报
    actions.api.article.search({
      type: "NEWS", from: 0, size: 10,
      request: {
          filters: { status: true },
          query: '科研圈日报',
          sort: [{ weight3: "DESC" }, { "onlineTime": "DESC" }],
      }
    }, res => this.setState({ daily: res.hits }))

    // 论文数据
    actions.api.thesis.search({
      type: "DEFAULT", from: 0, size: 16,
      request: {
          filters: { status: true },
          sort: [{ weight3: "DESC" }, { onlineTime: "DESC" }],
      },
    }, res => this.setState({ theses: res.hits }))

    // 论文排行
    mockxhr.POST(`/api/articles/search/week?type=THESIS&from=0&size=15`, {
      filters: {},
      sort: [],
    }, resp => {
      this.setState({ thesesRank: resp.hits })
    })

    // 广告数据
    mockxhr.POST(API.promotion.search("AD", "_", 99, 0), {}, resp => {
      (resp.hits || []).forEach(item => this.adStore[item.type1] = item)
      // this.setState({ adData: this.adStore })
    })

    // 科研生涯数据
    this.getMoreCareersData()

    // 招聘数据
    actions.api.job.search({
      type: "DEFAULT", from: 0, size: 10,
      query: "", filters: { online: true },
      sort: [{ "weight2": "DESC" }, { onlineTime: "DESC" }],
    }, res => this.setState({ jobs: res.hits }))

    // 热门招聘机构数据
    mockxhr.POST("/api/employers/search?" + qs.stringify({ type: "SEARCH", from: 0, size: 20 }), {
      filters: {}, query: "",
      sort: [{ weight: "DESC" }, { certApproveTime: "DESC" }],
    }, res => this.setState({ employerNames: res.hits.map(r => r.name) }))

    // 科学60秒数据
    actions.api.article.search({
      type: "CAREER", from: 0, size: 10, df: "title",
      request: {
          query: "科学60秒",
          filters: { columns: "科学60秒", status: true },
          sort: [{ "onlineTime": "DESC" }]
      }
    }, res => this.setState({ science60s: res.hits }))

    // 期刊数据
    // mockxhr.POST("/api/periodical/do?from=0&size=1000&type=SEARCH", {
    //   periodicalDao: {name: ""},
    //   sort: [],
    // }, resp => {
    //   let periodicalList = _.sortBy(_.filter(_.map(resp.hits.content, itm => {
    //     return {
    //       periodicalId: itm.id,
    //       checkname: itm.checkname,
    //       url: itm.url,
    //       logo: itm.logo,
    //       weight: itm.weight && itm.weight.split(';')[0],
    //       _weight: itm.weight && itm.weight.split(';')[1],
    //     }
    //   }), o => {
    //     return o.weight && o.weight > 0 && o._weight == 0
    //   }), k => {
    //     return k.weight
    //   })
    //   this.setState({ periodicalList })
    // })

    // 上滑加载更多生涯数据
    // let root = document.querySelector('#root')

    // if (root) {
    //   root.addEventListener('scroll', (e: any) => {
    //     let career = document.querySelector('.careers-wrapper')
  
    //     if ((career as any).getBoundingClientRect().top < (500 - (career as any).getBoundingClientRect().height)) {
    //       this.setState({ careerPage: this.state.careerPage + 1 }, () => {
    //         this.getMoreCareersData()
    //       })
    //     }
    //   })
    // }

    // 招聘滚动
    let fa_job = document.querySelector('#job-father');
    let ch_job = document.querySelector('.job-list-wrapper');

    timer_job = setInterval(() => {
      if (fa_job && ch_job) {
        if (fa_job.scrollTop >= ch_job.clientHeight / 2) {
          fa_job.scrollTop = 0
        } else {
          fa_job.scrollTop ++
        }
      }
    }, speed)

    // 新闻排行滚动
    let fa_news = document.querySelector('#information-father')
    let ch_news = document.querySelector('#information-child')

    timer_news = setInterval(() => {
      if (fa_news && ch_news) {
        if (fa_news.scrollTop >= ch_news.clientHeight / 2) {
          fa_news.scrollTop = 0
        } else {
          fa_news.scrollTop ++
        }
      }
    }, speed)

    // 论文排行滚动
    let fa_theses = document.querySelector('#theses-father')
    let ch_theses = document.querySelector('#theses-child')

    timer_theses = setInterval(() => {
      if (fa_theses && ch_theses) {
        if (fa_theses.scrollTop >= ch_theses.clientHeight / 2) {
          fa_theses.scrollTop = 0
        } else {
          fa_theses.scrollTop ++
        }
      }
    }, speed)

    // 鼠标移入停止滚动
    window.addEventListener('mouseover', (e: any) => {
      if (['week-rank', 'list-wrapper', 'rank-list-wrapper', 'list-item', 'job-list-wrapper', 'rank-number', 'rank-text', 'rank-periodical'].includes(e.target.className)) {
        clearInterval(timer_job)
        clearInterval(timer_news)
        clearInterval(timer_theses)
      }
    })

    // 鼠标移出继续滚动
    window.addEventListener('mouseout', (e: any) => {
      if (['week-rank', 'list-wrapper', 'rank-list-wrapper', 'list-item', 'job-list-wrapper', 'rank-number', 'rank-text', 'rank-periodical'].includes(e.target.className)) {
        timer_job = setInterval(() => {
          if (fa_job && ch_job) {
            if (fa_job.scrollTop >= ch_job.clientHeight / 2) {
              fa_job.scrollTop = 0
            } else {
              fa_job.scrollTop ++
            }
          }
        }, speed)

        timer_news = setInterval(() => {
          if (fa_news && ch_news) {
            if (fa_news.scrollTop >= ch_news.clientHeight / 2) {
              fa_news.scrollTop = 0
            } else {
              fa_news.scrollTop ++
            }
          }
        }, speed)
        
        timer_theses = setInterval(() => {
          if (fa_theses && ch_theses) {
            if (fa_theses.scrollTop >= ch_theses.clientHeight / 2) {
              fa_theses.scrollTop = 0
            } else {
              fa_theses.scrollTop ++
            }
          }
        }, speed)
      }
    })
    
    setTimeout(() => {
      this.Lazyload();
      (document.querySelector('#root') as any).addEventListener('scroll', () => {
        this.throttle(this.Lazyload(), 300)
      })
    }, 1500);
  }

  componentDidUpdate () {

  }

  componentWillUnmount () {
    clearInterval(timer_job)
    clearInterval(timer_news)
    clearInterval(timer_theses)
  }

  Lazyload () {
    let imgs = document.querySelectorAll('.news-item-wrapper')
    let seeHeight = document.documentElement.clientHeight
    let scrollTop = (document.querySelector('#root') as any).scrollTop

    _.forEach(imgs, item => {
      if (item.offsetTop < seeHeight + scrollTop) {
        if (item.children[0].children[0].style.backgroundImage === 'url("")') {
          item.children[0].children[0].style.backgroundImage = `url(${item.children[0].children[0].getAttribute("data-src")})`
        }
      }
    })
  }

  throttle (fun, delay) {
    let _this
    let _arguments
    let initTime = 0

    return function () {
      let now: any = new Date()
      _this = this
      _arguments = arguments

      if (now - initTime > delay) {
        fun.apply(_this, _arguments)
        initTime = now
      }
    }
  }

  ad(item, width, height, style?, showTitle?, clickPos?) {
      if (!item || !item.id) return null
      return (
          <a className={`ad ${showTitle && 'mask'}`} target="_blank" href={item.link} title={item.title} style={{...style }}
              onClick={() => actions.clickPromotion(item.id, () => 0, clickPos)}>
              {showTitle && <div className='title'>{item.title}</div>}
              {util.imageMask(item.image, width, height, () => 0, {}, false, {zIndex: 6})}
          </a>
      )
  }

  blockHeader (name, link, subtitle) {
    return (
      <div className='top'>
        <span
          className='block-name'
          onClick={() => {
            window.open(link)
          }}
        >
          {name}
          <span className='subtitle'>{subtitle}</span>
        </span>

        <span
          className='read-more'
          onClick={() => {
            window.open(link)
          }}
        >
          查看更多
        </span>
      </div>
    )
  }

  newsItemWrapper (i, big = false) {
    let title = i.title
    if (title.includes('▶ 科学60秒：')) {
      title = title.split("|")[0]
    }
    title = title.replace('科研圈日报：', '').replace('▶ 科学60秒：', '')

    return (
      <div 
        className='news-item-wrapper'
        key={i.id}
        style={{
          width: `${big ? '257px' : '238px'}`,
          flex: `${big ? '0 0 257px' : '0 0 238px'}`
        }}
        onClick={() => {
          window.open(`/${i.type === 'CAREER' ? 'careers' : 'information'}/${i.id}`)
        }}
      >
        {util.imageMask(i.cover, (big ? 257 : 238), (big ? 148 : 136), () => 0)}
        <div className='title'>
          {title}
        </div>

        <div className='bottom'>
          {util.getNewSourceLogoByQiNiu(i.source)}
          <span className='name'>
            {util.getNewSourceName(i.source)}
          </span>

          <span className='date'>
            {moment(i.onlineTime).format('MM/DD')}
          </span>
        </div>
      </div>
    )
  }

  thesesItemWrapper (i) {
    let subjects = i.subject.split(';').slice(0, 2)

    return(
      <div
        className='theses-item-wrapper'
        onClick={() => window.open(`/theses/${i.id}`)}
      >
        <div className='title'>{i.title}</div>
        <div className='bottom'>
          <span className='periodical'>{i.journals && i.journals.join(';')}</span>
          <span className='subject'>
            {_.map(subjects, (itm, idx) => {
              return <span className='subject-item' onClick={() => window.open(`/searchall?tab=theses&filters.subject=${encodeURIComponent(itm)}`)}>{itm}{((idx + 1) < subjects.length) && ','}</span>
            })}
          </span>
        </div>
      </div>
    )
  }

  jobItemWrapper (i) {
    return (
      <div 
        className='job-item-wrapper'
        key={i.id}
        onClick={() => {
          window.open(`/jobs/${i.id}`)
        }}
      >
        <div className='job-left'>
          <div className="title">
            {util.fullEmployerName(i.employerName, i.department)}
          </div>
          <div className='sub'>
            <span className='name'>{i.name}</span>
            <span className='other'>
              <span className='salary'>{util.salaryText(i)}</span>
              <span className='city'>{i.city}</span>
            </span>
          </div>
        </div>

        <div className='job-right'>
          {util.imageCover(i.employerLogo, 70, { height: 70, border: "0px solid #e5e5e5" })}
        </div>
      </div>
    )
  }

  rankList (data, urlKey, id) {
    return (
      <div className='rank-list-wrapper' id={id}>
        {_.map(data, (item, index) => {
          return (
            <div 
              className='list-item'
              key={item.id}
              onClick={() => {
                window.open(`/${urlKey}/${item.id}`)
              }}
            >
              <span
                className='rank-number'
                style={{
                  background: `${index <= 2 ? RANK_COLOR[index] : '#989898'}`
                }}
              >
                {index + 1}
              </span>

              <span className='rank-text'>{item.title}</span>

              {urlKey === 'theses' && <div className='rank-periodical'>{item.journals && item.journals.join(';')}</div>}
            </div>
          )
        })}
        <div style={{width: '314px', height: '2px', marginLeft: '-44px', border: '1px dashed rgb(51, 51, 51)'}} />
        {_.map(data, (item, index) => {
          return (
            <div 
              className='list-item'
              key={item.id}
              onClick={() => {
                window.open(`/${urlKey}/${item.id}`)
              }}
            >
              <span
                className='rank-number'
                style={{
                  background: `${index <= 2 ? RANK_COLOR[index] : '#989898'}`
                }}
              >
                {index + 1}
              </span>

              <span className='rank-text'>{item.title}</span>

              {urlKey === 'theses' && <div className='rank-periodical'>{item.journals && item.journals.join(';')}</div>}
            </div>
          )
        })}
      </div>
    )
  }

  jobList (data) {
    return (
      <div className='job-list-wrapper'>
        {_.map([...data, ...data], (item, index) => {
          return (
            <div
              className='list-item'
              key={index}
              onClick={() => {
                window.open(`/searchall?tab=jobs&query=${encodeURIComponent(item)}`)
              }}
            >
              {item}
            </div>
          )
        })}
      </div>
    )
  }

  getMoreCareersData () {
    let status = true

    if (!status) return
    status = false
    actions.api.article.search({
      type: "CAREER", from: this.state.careerPage * 21, size: 21,
      request: {
          filters: { status: true },
          sort: [{ "weight2": "DESC" }, { "onlineTime": "DESC" }]
      },
    }, res => {
      this.setState({ careers: this.state.careers.concat(res.hits) })
      this.Lazyload()
      status = true
    })
  }

  render () {
    let { slides, headlines, daily, newsRank, theses, science60s, thesesRank, jobs, employerNames, careers, periodicalList } = this.state
    careers = careers.filter(x => headlines && headlines.map(h => h.id).indexOf(x.id) < 0)
    // 如果theses为空则是白屏
    if(!this.state.theses) {
      console.error("白屏原因：论文频道没有论文")
      return null
    }
    let _theses = theses[0].subject.split(";").slice(0, 2)
    let thesesSubject = _.cloneDeep(_theses)
    _theses.forEach(i => {
        if (i.length >= 4) {
            thesesSubject = _theses.slice(0, 1)
        }
    })
    let _slides = _.cloneDeep(slides)
    let _slidesAd = _slides.splice(1, 2)

    return (
      <div className='index2019-wrapper'>
        <Header tab="INDEX" search='theses' style={{maxWidth: '1100px', minWidth: '1100px'}} />

        <div className='content-wrapper'>
          {/* 轮播图、广告 */}
          <div className='carousel-wrapper'>
            <div className='slider-area'>
              {_slides.length > 1 && <Icon className='left' type="left" onClick={() => (this.refs.carousel as any).prev()} />}
              <Carousel autoplay ref='carousel'>
                {_.map(_slides, i => {
                  return (
                    <a className='slider-item' href={i.link} title={i.title}  onClick={() => actions.clickPromotion(i.id, () => 0, 'index')}>
                      {/* {util.imageCover(i.image, 760, { height: 370 })} */}
                      {util.imageMask(i.image, 760, 370, () => 0, {}, false, {zIndex: 6})}
                      <div className="title">{i.title}</div>
                    </a>
                  )
                })}
              </Carousel>
              {_slides.length > 1 && <Icon className='right' type="right" onClick={() => (this.refs.carousel as any).next()} />}
            </div>

            <div className='add-area'>
              {this.ad(_slidesAd[0], 314, 173, {marginBottom: '23px'  }, true, 'index')}
              {this.ad(_slidesAd[1], 314, 173, {}, true, 'index')}
            </div>
          </div>

          {/* 新闻 */}
          <div className="dotted-line"></div>
          <div className='headlines-wrapper main-wrapper'>
            {this.blockHeader('新闻', '/information', 'News')}

            <div className='content'>
              <div className='left dis-wrap'>
                {_.map(headlines, i => {
                  return (
                    <a 
                      className='news-item-wrapper'
                      key={i.id}
                      style={{
                        width: '238px',
                        flex: '0 0 238px',
                      }}
                      href={`/${i.type === 'CAREER' ? 'careers' : 'information'}/${i.id}`}
                      target='blank'
                    >
                      {util.LazyImageMask(i.cover, '', 238, 136, () => 0)}
                      <div className='title'>
                        {i.title.includes('▶ 科学60秒：') ? i.title.split("|")[0].replace('科研圈日报：', '').replace('▶ 科学60秒：', '') : i.title.replace('科研圈日报：', '').replace('▶ 科学60秒：', '') }
                      </div>
              
                      <div className='bottom'>
                        {util.getNewSourceLogoByQiNiu(i.source)}
                        <span className='name'>
                          {util.getNewSourceName(i.source)}
                        </span>
              
                        <span className='date'>
                          {moment(i.onlineTime).format('MM/DD')}
                        </span>
                      </div>
                    </a>
                  )
                })}
              </div>

              <div className='right'>
                <div className='week-rank' id='information-father' style={{ marginBottom: '24px'}}>
                  <div className='title'>
                    <img className='icon' src='/assets/icons/crown-red.svg' />
                    一周新闻排行
                  </div>

                  <div style={{ maxHeight: '595px'}} className='list-wrapper'>
                    {<div className='rank-list-wrapper' id='information-child'>
                      <div ref="rank">
                      {_.map(newsRank, (item, index) => {
                        return (
                          <a 
                            className='list-item'
                            key={item.id}
                            href={`/information/${item.id}`}
                            target='blank'
                            style={{
                              borderBottom:  newsRank.length === (index + 1) ? 'none' : '1px solid #eaeaea',
                            }}
                          >
                            <span
                              className='rank-number'
                              style={{
                                background: `${index <= 2 ? RANK_COLOR[index] : '#989898'}`
                              }}
                            >
                              {index + 1}
                            </span>
              
                            <span className='rank-text'>{item.title}</span>
                          </a>
                        )
                      })}
                      </div>
                      {this.state.rankScroll && <div style={{width: '314px', height: '8px', margin: '5px 0 15px -44px', background: '#eaeaea'}} />}
                      {this.state.rankScroll && _.map(newsRank, (item, index) => {
                        return (
                          <a 
                            className='list-item'
                            key={item.id}
                            href={`/information/${item.id}`}
                            target='blank'
                          >
                            <span
                              className='rank-number'
                              style={{
                                background: `${index <= 2 ? RANK_COLOR[index] : '#989898'}`
                              }}
                            >
                              {index + 1}
                            </span>
              
                            <span className='rank-text'>{item.title}</span>
                          </a>
                        )
                      })}
                    </div>}
                  </div>
                </div>
                
                {this.ad(this.adStore["HOME_SHORT_3"], 320, 173)}
              </div>
            </div>
            
            <div className='subtitle' onClick={() => {window.open(`${location.origin}/information?filters.columns=%E7%A7%91%E7%A0%94%E5%9C%88%E6%97%A5%E6%8A%A5&filters.status=true&query=`)}}>
              <img className='icon' src='/assets/icons/book-2.svg' />
              科研圈日报
            </div>
            {util.horizontalWrapper(
                <div className='horizontal-content daily' style={{height: '340px'}}>
                {_.map(daily, i => {
                  return (
                    <a 
                      className='horizontal-item daily-item'
                      href={`/information/${i.id}`}
                      target='blank'
                      // onClick={() => window.open(`/information/${i.id}`)}
                    >
                      <div className='daily-date'>{moment(i.onlineTime).format('YYYY/MM/DD')}</div>
                      <div className="daily-img">{util.imageMask(i.cover, 238, 136, () => 0)}</div>
                      <div className="daily-title"><p><span className="daily-title-bottom">{i.title.replace("科研圈日报：","")}</span></p></div>
                      <div className="daily-summary">
                        {i.summary && _.map(i.summary.split('；').slice(0, 2), i => {
                          return (
                            <div className='daily-summary-item purple-hover'>· {i.replace("更多：","")}</div>
                          )
                        })}
                      </div>
                    </a>
                  )
                })}
              </div>, '.daily', 263
              )}
          </div>

          {/* 论文 */}
          {this.adStore.HOME_LONG_2.id ?
            <div className="min-view-center" style={{ paddingTop: 60 }}>
              {this.ad(this.adStore.HOME_LONG_2, 1100, 120)}
            </div>
            : null
          }
          <div className="dotted-line"></div>
          <div className='theses-wrapper'>
            {this.blockHeader('论文', '/theses', 'Research')}

            {(periodicalList.length > 0) && util.horizontalWrapper(
              <div className='horizontal-content periodical' style={{height: '143px', marginBottom: '46px', marginTop: '-20px'}}>
              {_.map(periodicalList, (itm, idx) => {
                return (
                  <div className='horizontal-item periodical-item'>
                    <div 
                      className='periodical-block'
                      style={{
                        backgroundImage: `url(${itm.logo})`
                      }}
                      onClick={() => window.open(itm.url)}
                    />
                  </div>
                )
              })}
            </div>, '.periodical'
            )}

            <div className='main-wrapper'>
              <div className='content'>
                <div className='left'>
                  <div className='subtitle' onClick={() => window.open('/theses')} style={{marginTop: 0}}>
                    <img className='icon' src='/assets/icons/new.svg' />
                    最新上线
                  </div>
                  {theses[0] && <a
                    className='first-theses'
                    href={`/theses/${theses[0].id}`}
                    target='blank'
                    // onClick={() => window.open(`/theses/${theses[0].id}`)}
                  >
                    <div className='first-theses-left'>{util.imageMask(theses[0].cover, 500, 210)}</div>
                    <div className='first-theses-right'>
                      <div className='title'>{theses[0].title}</div>
                      {/* <div className='text'>{util.htmlToPlain(theses[0].summary)}</div> */}
                      <div className='bottom'>
                        <span className='periodical-area'>
                          <span className='periodical purple-hover'>{theses[0].journals && theses[0].journals.join('; ')}</span>
                        </span>
                        <span className='subject purple-hover'>
                          {_.map(thesesSubject, (itm, idx) => {
                            return <span className='subject-item' onClick={() => window.open(`/searchall?tab=theses&filters.subject=${encodeURIComponent(itm)}`)}>{itm}{((idx + 1) < thesesSubject.length) && ','}</span>
                          })}
                        </span>
                      </div>
                    </div>
                  </a>}

                  <div className='other-theses dis-wrap'>
                    {_.map(theses.slice(1), (i, index) => {
                      return(
                        <a
                          className='theses-item-wrapper'
                          href={`/theses/${i.id}`}
                          target='blank'
                          // onClick={() => window.open(`/theses/${i.id}`)}
                        >
                          {index < 3 && <div className="img">{util.imageMask(i.cover, 240, 95, () => 0)}</div>}
                          <div className='title'>{i.title}</div>
                          <div className='bottom'>
                            <span className='periodical'>{i.journals && i.journals.join('; ')}</span>
                            <span className='subject'>
                              {_.map(i.subject.split(';').slice(0, 2), (itm, idx) => {
                                return <span className='subject-item' onClick={() => window.open(`/searchall?tab=theses&filters.subject=${encodeURIComponent(itm)}`)}>{itm}{((idx + 1) < i.subject.split(';').slice(0, 2).length) && ','}</span>
                              })}
                            </span>
                          </div>
                        </a>
                      )
                    })}
                  </div>
                </div>

                <div className='right'>
                  <div className='week-rank' id='theses-father' style={{ marginBottom: '24px', maxHeight: '785px'}}>
                    <div className='title'>
                      <img className='icon' src='/assets/icons/crown-red.svg' />
                      一周论文排行
                    </div>

                    <div className='list-wrapper'>
                      {<div className='rank-list-wrapper' id='theses-child'>
                        {_.map(thesesRank, (item, index) => {
                          return (
                            <a 
                              className='list-item'
                              key={item.id}
                              href={`/theses/${item.id}`}
                              target='blank'
                              style={{
                                borderBottom:  thesesRank.length === (index + 1) ? 'none' : '1px solid #eaeaea',
                              }}
                            >
                              <span
                                className='rank-number'
                                style={{
                                  background: `${index <= 2 ? RANK_COLOR[index] : '#989898'}`
                                }}
                              >
                                {index + 1}
                              </span>
                
                              <span className='rank-text'>{item.title}</span>
                
                              <div className='rank-periodical'>{item.journals && item.journals.join('; ')}</div>
                            </a>
                          )
                        })}
                        {thesesRank.length > 8 && <div style={{width: '314px', height: '8px', margin: '5px 0 15px -44px', background: '#eaeaea'}} />}
                        {thesesRank.length > 8 && _.map(thesesRank, (item, index) => {
                          return (
                            <a 
                              className='list-item'
                              key={item.id}
                              href={`/theses/${item.id}`}
                              target='blank'
                            >
                              <span
                                className='rank-number'
                                style={{
                                  background: `${index <= 2 ? RANK_COLOR[index] : '#989898'}`
                                }}
                              >
                                {index + 1}
                              </span>
                
                              <span className='rank-text'>{item.title}</span>
                
                              <div className='rank-periodical'>{item.journals && item.journals.join('; ')}</div>
                            </a>
                          )
                        })}
                      </div>}
                    </div>
                  </div>

                  {this.ad(this.adStore["HOME_SHORT_4"], 320, 180)}
                </div>
              </div>
            </div>

            <div className='subtitle' onClick={() => {window.open(`${location.origin}/careers?filters.columns=科学60秒&filters.status=true&query=`)}}>
              <img className='icon' src='/assets/icons/music.svg' />
              音频导读·科学60秒
            </div>
            {util.horizontalWrapper(
                <div className='horizontal-content science' style={{height: '195px'}}>
                {_.map(science60s, i => {
                  return (
                    <a
                      className='horizontal-item science-item'
                      href={`/careers/${i.id}`}
                      target='blank'
                    >
                      <div className='science-top' style={{backgroundImage: `url(${i.cover})`}}>
                        <div className="science-title"><span className="science-title-bottom">{i.title.split('|')[0].split('：')[1]}</span></div>
                        {/* <div className="science-summary">{i.summary}</div> */}
                      </div>
                      <div className='science-bottom'>
                        <span className='science-bottom-play'>
                          <Icon type="play-circle" />
                        </span>
                        <span className='science-bottom-time'>
                          {i.title.split('|')[1]}
                        </span>
                      </div>
                    </a>
                  )
                })}
              </div>, '.science'
              )}
          </div>

          {/* 招聘 */}
          {this.adStore.HOME_LONG_3.id ?
            <div className="min-view-center" style={{ paddingTop: 60 }}>
              {this.ad(this.adStore.HOME_LONG_3, 1100, 120)}
            </div>
            : null
          }
          <div className="dotted-line"></div>
          <div className='job-wrapper main-wrapper'>
            {this.blockHeader('招聘', '/search', 'Jobs')}
            <div className='content'>
              <div className='left dis-wrap'>
                {_.map(jobs, i => {
                  return (
                    <a 
                      className='job-item-wrapper'
                      key={i.id}
                      href={`/jobs/${i.id}`}
                      target='blank'
                    >
                      <div className='job-left'>
                        <div className="title">
                          {util.fullEmployerName(i.employerName, i.department)}
                        </div>
                        <div className='sub'>
                          <span className='name'>{i.name}</span>
                          <div className='other'>
                            <div className='salary'>{util.salaryText(i)}</div>
                            <div className='city'>{i.city}</div>
                          </div>
                        </div>
                      </div>
              
                      <div className='job-right'>
                        {util.imageCover(i.employerLogo, 70, { height: 70, border: "0px solid #e5e5e5" })}
                      </div>
                    </a>
                  )
                })}
              </div>

              <div className='right'>
                <div className='week-rank' id='job-father'>
                  <div className='title'>
                    <img className='icon' src='/assets/icons/fire.svg' />
                    热门招聘机构
                  </div>

                  <div style={{maxHeight: '480px'}} className='list-wrapper'>
                    {<div className='job-list-wrapper'>
                      {_.map([...employerNames, ...employerNames], (item, index) => {
                        return (
                          <a
                            className='list-item'
                            key={index}
                            href={`/searchall?tab=jobs&query=${encodeURIComponent(item)}`}
                            target='blank'
                            // onClick={() => {
                            //   window.open(`/searchall?tab=jobs&query=${encodeURIComponent(item)}`)
                            // }}
                          >
                            {item}
                          </a>
                        )
                      })}
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 科研生涯 */}
          {this.adStore.HOME_LONG_4.id ?
            <div className="min-view-center" style={{ paddingTop: 60 }}>
              {this.ad(this.adStore.HOME_LONG_4, 1100, 120)}
            </div>
            : null
          }
          <div className="dotted-line"></div>
          <div className='careers-wrapper'>
            {this.blockHeader('科研生涯', '/careers', 'Careers')}
            <div className='dis-wrap'>
              {_.map(careers.slice(0, 12 * (this.state.careerPage + 1)), i => {
                return (
                  <a 
                    className='news-item-wrapper'
                    key={i.id}
                    style={{
                      width: '257px',
                      flex: '0 0 257px',
                    }}
                    href={`/${i.type === 'CAREER' ? 'careers' : 'information'}/${i.id}`}
                    target='blank'
                  >
                    {util.LazyImageMask(i.cover, '', 257, 148, () => 0)}
                    <div className='title'>
                      {i.title.includes('▶ 科学60秒：') ? i.title.split("|")[0].replace('科研圈日报：', '').replace('▶ 科学60秒：', '') : i.title.replace('科研圈日报：', '').replace('▶ 科学60秒：', '') }
                    </div>
            
                    <div className='bottom'>
                      {util.getNewSourceLogoByQiNiu(i.source)}
                      <span className='name'>
                        {util.getNewSourceName(i.source)}
                      </span>
            
                      <span className='date'>
                        {moment(i.onlineTime).format('MM/DD')}
                      </span>
                    </div>
                  </a>
                )
              })}
            </div>
          </div>

          {/* 加载更多 */}
          <div className='more' onClick={() => {
            this.setState({ careerPage: this.state.careerPage + 1 }, () => {
              this.getMoreCareersData()
            })
          }}>
            {/* <Icon type="loading" /> */}
            加载更多
          </div>
        </div>

        <Partner />
        <Footer />
      </div>
    )
  }
}

ReactDOM.render(<View />, document.body.firstChild as any)